import styles from "./applyStep.module.css"
import styleSteps from "./applySteps.module.css"
import arrowImage from "../../images/applyStep/icon-arrow.png"
import step1img from "../../images/applyStep/step-1.png"
import step2img from "../../images/applyStep/step-2.png"
import step3img from "../../images/applyStep/step-3.png"
import { useState } from "react"

const ApplySteps = () => {
  const selectedFontColor = "#ffffff"
  const unselectFontColor = "#989898"
  const selectedStepColor = "#3fdecc"
  const unselectStepColor = "#ffffff"
  const [selectedStep, setSelectedStep] = useState(1)
  const [rightSectionImg, setRightSectionImg] = useState(step1img)

  const ImgList = [step1img, step2img, step3img]
  const data = [
    {
      stepidx: 1,
      stepNumber: "01",
      stepTitle: "Online申請開戶",
      rightSideTitle: "網上填妥簡單表格",
      rightSideConetnt:
        "只須提供簡單個人資料，2分鐘完成登記，閣下提供的資料絕對全程保密",
    },
    {
      stepidx: 2,
      stepNumber: "02",
      stepTitle: "批核",
      rightSideTitle: "網上填妥簡單表格",
      rightSideConetnt:
        "填妥簡單個人資料後，10分鐘內可獲悉貸款初步貸款批核結果如貸款額、貸款利率及還款期數等",
    },
    {
      stepidx: 3,
      stepNumber: "03",
      stepTitle: "放款",
      rightSideTitle: "網上填妥簡單表格",
      rightSideConetnt:
        "確認貸款資料無誤後，最快15分鐘FPS貸款額至閣下銀行戶口，全程零隱藏收費，足不出戶新貸度",
    },
  ]

  const [rightSideTitle, setRightSideTitle] = useState(data[0].rightSideTitle)
  const [rightSideContent, setRightSectionContent] = useState(
    data[0].rightSideConetnt
  )

  return (
    <div className={styleSteps.container}>
      <h2>申請步驟</h2>
      <p>點樣申請FFL私人貸款呢？</p>
      <br />
      <div className={styleSteps.titleSection}>
        <div className={styleSteps.leftSection}>
          {data.map((item, idx) => (
            <div
              key={idx}
              className={styles.step}
              style={{
                backgroundColor:
                  selectedStep === item.stepidx
                    ? selectedStepColor
                    : unselectStepColor,
                color:
                  selectedStep === item.stepidx
                    ? selectedFontColor
                    : unselectFontColor,
                boxShadow:
                  selectedStep === item.stepidx
                    ? "#3fdecc 0px 0px 11px 2px"
                    : "unset",
              }}
              onClick={() => {
                setSelectedStep(item.stepidx)
                setRightSectionImg(ImgList[item.stepidx - 1])
                setRightSideTitle(item.rightSideTitle)
                setRightSectionContent(item.rightSideConetnt)
              }}
            >
              <div className={styles.itemLeft}>
                <h2 className={styles.stepNumber}>{item.stepNumber}</h2>
                <h4 className={styles.stepTitle}>{item.stepTitle}</h4>
              </div>
              <div className={styles.arrowWrapper}>
                <img
                  src={arrowImage}
                  alt="Arrow"
                  className={styles.stepArrow}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={styleSteps.rightSection}>
          <h2>{rightSideTitle}</h2>
          <div className={styleSteps.threeBoxs}>
            <div className={styleSteps.box1}></div>
            <div className={styleSteps.box2}></div>
            <div className={styleSteps.box3}></div>
          </div>
          <div className={styleSteps.rightSection_item}>
            <h4>{rightSideContent}</h4>
            <img
              src={rightSectionImg}
              alt="step1_img"
              style={{ width: selectedStep === 1 ? "300px" : "400px" }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplySteps
