import { Fragment } from "react"
import "./css/featureGrid.css"
import { Grid, Paper, Typography, Box } from "@mui/material"
import icon1 from "../images/creditcard/icon-1.png"
import icon2 from "../images/creditcard/icon-2.png"
import icon3 from "../images/creditcard/icon-3.png"
import icon4 from "../images/creditcard/icon-4.png"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import css from "./css/cardSlider.module.css"
import { useMediaQuery } from "react-responsive"

const List1 = [
  {
    icon: icon1,
    title: "以較低息的貸款去處理較高息的卡數及貸款結欠,節省利息及手續費支出",
  },
  { icon: icon2, title: "合併多項信用卡卡數及貸款結欠，以便集中處理欠款" },
  {
    icon: icon3,
    title: "以較長期的貸款取代眼前的卡數及貸款結欠，減低每月還款額",
  },
  {
    icon: icon4,
    title: "制定清晰的還款時間表，縮短整體還款期（對比只還Min Pay）",
  },
]

const FeatureCard = ({ icon, title }) => {
  const titleParts = title.split("/n").map((part, index) => (
    <Fragment key={index}>
      {part}
      {index < title.split("/n").length - 1 && <br />}
    </Fragment>
  ))

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      elevation={2}
      className="feature-card-paper"
    >
      <Box sx={{ mb: 2, height: "100px" }}>
        <img src={icon} alt="icon" className="feature-card-img" />
      </Box>
      <Typography
        variant="span"
        component="span"
        sx={{ mb: 1, fontWeight: "500", fontSize: "1rem" }}
      >
        {titleParts}
      </Typography>
      {/* <Typography variant="body1">{description}</Typography> */}
    </Paper>
  )
}

export default function FeatureGrid_CreditCard() {
  const isBreakpoint = useMediaQuery({ minWidth: 300, maxWidth: 768 })
  const settings = {
    className: `${css.cardSlider_container}`,
    centerPadding: "20px",
    centerMode: true,
    swipeToSlide: true,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <div className="featureGrid-papers-container">
      {!isBreakpoint ? (
        <Grid container spacing={2} className="feature-grid">
          {List1.map((item, idx) => (
            <Grid
              item
              xs={2}
              sm={6}
              md={2.5}
              className="feature-grid-item"
              key={idx}
            >
              <FeatureCard icon={item.icon} title={item.title} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Slider {...settings}>
          {List1.map((item, idx) => (
            <div className={css.item}>
              <Grid
                item
                xs={2}
                sm={6}
                md={2.5}
                className="feature-grid-item"
                key={idx}
              >
                <FeatureCard icon={item.icon} title={item.title} />
              </Grid>
            </div>
          ))}
        </Slider>
      )}
    </div>
  )
}
