import "./App.css"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import { FormDatatext } from "./hooks/useFormatDatatext"

import Home from "./all_pages/Home"
import PrivateLoan from "./all_pages/PrivateLoan"
import CollateralLoan from "./all_pages/CollateralLoan"
import Tu from "./all_pages/Tu"
import Blog from "./all_pages/Blog"
import CreditCard from "./all_pages/CreditCard"
import ErrorPage from "./all_pages/ErrorPage"
import Mortgage from "./all_pages/Mortgage"
import Profile from "./all_pages/Profile"
import TopMenu from "./compoients/TopMenu"
import "bootstrap/dist/css/bootstrap.min.css"
import Footer from "./compoients/Footer"
import AboutUs from "./all_pages/AboutUs"
import QA from "./all_pages/QA"
import useFetch from "./hooks/useFetch"
import { ENV } from "./config"
import Apply from "./all_pages/Apply"
import Thankyou from "./all_pages/Thankyou"

//signature
// import Login from "./signature/Login"
// import SignUp from "./signature/components/SignUp/SignUp"
// import AdminDashBoard from "./signature/components/AdminDashboard/AdminDashBoard"
// import ClientsList from "./signature/components/ClientsList/ClientsList"
// import ClientDetail from "./signature/components/ClientDetail/ClientDetail"
// import UserDashboard from "./signature/components/UserDashboard/UserDashboard"
// import DocSign from "./signature/components/DocSign/DocSign"
// import ClientUnsign from "./signature/components/ClientSigned/ClientSigned"
// import ChangePassowrd from "./signature/components/ChangePassword/ChangePassword"
// import EditContract from "./signature/components/EditContract/EditContract"
// import AdminLogin from "./signature/AdminLogin"

//end signature
import { AuthContextProvider } from "./AuthContext"
import FloatBtnList from "./compoients/FloatBtnList"
import PageHead from "./compoients/PageHead"
import BlogPostPage from "./compoients/BlogPostPage"
import { MetaPixel } from "./compoients/MetaPixel"
import GoogleTagManager from "./compoients/GoogleTagManager"
import GoogleAdsConversion from "./compoients/GoogleAdsConversion"

function App() {
  const { data } = useFetch(ENV + "/api/v1/content")
  const GtagID = data?.GtagID?.find(
    (item) => item.label === "GtagID"
  )?.description
  const GtagLabel = data?.GtagLabel?.find(
    (item) => item.label === "GtagLabel"
  )?.description

  return (
    <Router>
      <AuthContextProvider>
        <MetaPixel content={data} />
        <GoogleTagManager id={GtagID} />
        <GoogleAdsConversion id={GtagID} label={GtagLabel} />

        <TopMenu content={data} />
        <div className="app-container">
          <FloatBtnList data={data} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/apply" element={<Apply />} />
            <Route path="/thankyou" element={<Thankyou data={data} />} />
            <Route path="/blog" element={<Blog />} />
            <Route
              path="/blog/:postId"
              element={
                <PageHead
                // title={blogMetaData.title}
                // description={blogMetaData.description}
                // keywords={blogMetaData.keywords}
                >
                  <BlogPostPage />
                </PageHead>
              }
            />
            <Route path="/privateloan" element={<PrivateLoan />} />
            <Route path="/collateralLoan" element={<CollateralLoan />} />
            <Route path="/creditcard" element={<CreditCard />} />
            <Route path="/mortgage" element={<Mortgage />} />
            <Route path="/tu" element={<Tu />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/qa" element={<QA />} />
            <Route path="/profile/:username" element={<Profile />} />
            <Route path="*" element={<ErrorPage />} />

            {/*signature*/}

            {/* <Route exact path="/adminLogin" element={<AdminLogin />} />

            <Route exact path="/login" element={<Login />} />

            <Route exact path="/signup" element={<SignUp />} />

            <Route exact path="/admindashboard" element={<AdminDashBoard />} />

            <Route exact path="/clientslist" element={<ClientsList />} />

            <Route exact path="/clientdetail/:id" element={<ClientDetail />} />

            <Route exact path="/clientsigned/:id" element={<ClientUnsign />} />

            <Route
              exact
              path="/changepassword/:id"
              element={<ChangePassowrd />}
            />

            <Route
              exact
              path="/userdashboard/:id"
              element={<UserDashboard />}
            />

            <Route
              exact
              // path="/docsign/:filename/:id/:loanNo/:Borrower"
              path="/docsign/:filename/:id"
              element={<DocSign />}
            />
            <Route exact path="/editContract/:id" element={<EditContract />} /> */}

            {/*end signature*/}
          </Routes>
          <div className="footer-remarks">
            <div>
              {FormDatatext(
                `註:根據《放債人條例》年利率最高不超過48厘。\n供款期由6個月至個120月常見還款例子:年利率為30%情況下的貸款HK$10,000，還款期數12期，每期還款額為HK$975，總還款額為HK$11,700`
              )}
            </div>
          </div>
          <Footer data={data} />
        </div>
      </AuthContextProvider>
    </Router>
  )
}

export default App
