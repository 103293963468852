import { Fragment } from "react"
import "./css/featureGrid.css"
import { Grid, Paper, Typography, Button, Box } from "@mui/material"
import ImageIcon from "@mui/icons-material/Image" // Placeholder for the icon, replace with your icons
import icon1 from "../images/privateloan/icon-1.png"
import icon2 from "../images/privateloan/icon-2.png"
import icon3 from "../images/privateloan/icon-3.png"
import icon4 from "../images/privateloan/icon-4.png"
import icon5 from "../images/privateloan/icon-5.png"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import css from "./css/cardSlider.module.css"
import { useMediaQuery } from "react-responsive"

const FeatureCard = ({ icon, title, description }) => {
  const titleParts = title.split("/n").map((part, index) => (
    <Fragment key={index}>
      {part}
      {index < title.split("/n").length - 1 && <br />}
    </Fragment>
  ))

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      elevation={2}
      className="feature-card-paper"
    >
      <Box sx={{ mb: 2, height: "100px" }}>
        <img src={icon} alt="icon" className="feature-card-img" />
      </Box>
      <div style={{ height: "100px" }}>
        <Typography
          variant="h5"
          component="h5"
          sx={{ mb: 1, fontWeight: "500" }}
        >
          {titleParts}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </div>
    </Paper>
  )
}

const ActionCard = ({ title, smallText, buttonText }) => {
  const titleParts = title.split("/n").map((part, index) => (
    <Fragment key={index}>
      {part}
      {index < title.split("/n").length - 1 && <br />}
    </Fragment>
  ))
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "300px",
        border: "none",
        boxShadow: "none",
      }}
      elevation={2}
    >
      <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
        {titleParts}
      </Typography>
      <Typography variant="body2" sx={{ mb: 3 }}>
        {smallText}
      </Typography>

      <button className="feature-action-card-btn button_hover_motion2" onClick={() => window.location.href = "/apply"}>
        {buttonText}
      </button>
    </Paper>
  )
}

export default function FeatureGrid() {
  const isBreakpoint = useMediaQuery({ minWidth: 300, maxWidth: 768 })
  const settings = {
    className: `${css.cardSlider_container}`,
    centerPadding: "20px",
    centerMode: true,
    swipeToSlide: true,
    autoplay: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const List1 = [
    {
      icon: icon1,
      title: "分期貸款",
      desc: "最常見的私人貸款模式，借款人獲批貸款後，每月定期及定額地還款",
    },
    {
      icon: icon2,
      title: "循環貸款",
      desc: "申請人獲批貸款限額後，還款期及還款額視乎借款人實際提取的金額而定，原理與信用卡簽賬類似",
    },
    {
      title: "貸款符合各種個人理財需要/n網上私人貸款即時批核！",
      smallText: "申請貸款只需身份證及電話，借錢更方便。",
    },
    {
      icon: icon3,
      title: "結餘轉戶貸款",
      desc: "用途是以較佳利率還清其他貸款，而非用作提供現金，但近年市面亦有可用作套現的結餘轉戶貸款",
    },
    {
      icon: icon4,
      title: "稅務貸款",
      desc: "為私人分期貸款的其中一種，常於每年10月至翌年4月推出，利率通常比一般私人貸款優惠。貸款用途名義上是交稅，但實際上沒有限制",
    },
    {
      icon: icon5,
      title: "汽車貸款",
      desc: "用汽車作為抵押品，以取得貸款購買汽車，然後分期償還貸款",
    },
  ]

  return (
    <>
      {!isBreakpoint ? (
        <>
          <Grid container spacing={4} className="feature-grid">
            <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
              <FeatureCard
                icon={icon1}
                title="分期貸款"
                description="最常見的私人貸款模式，借款人獲批貸款後，每月定期及定額地還款"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
              <FeatureCard
                icon={icon2}
                title="循環貸款"
                description="申請人獲批貸款限額後，還款期及還款額視乎借款人實際提取的金額而定，原理與信用卡簽賬類似"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
              <ActionCard
                title="貸款符合各種個人理財需要/n網上私人貸款即時批核！"
                smallText="申請貸款只需身份證及電話，借錢更方便。"
                buttonText="了解更多"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
              <FeatureCard
                icon={icon3}
                title="結餘轉戶貸款"
                description="用途是以較佳利率還清其他貸款，而非用作提供現金，但近年市面亦有可用作套現的結餘轉戶貸款"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
              <FeatureCard
                icon={icon4}
                title="稅務貸款"
                description="為私人分期貸款的其中一種，常於每年10月至翌年4月推出，利率通常比一般私人貸款優惠。貸款用途名義上是交稅，但實際上沒有限制"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="feature-grid-item">
              <FeatureCard
                icon={icon5}
                title="汽車貸款"
                description="用汽車作為抵押品，以取得貸款購買汽車，然後分期償還貸款"
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <div className="featureGrid-papers-container">
          <Slider {...settings}>
            {List1.map((item, idx) => {
              if (item.icon) {
                return (
                  <div className={css.item}>
                    <FeatureCard
                      icon={item.icon}
                      title={item.title}
                      description={item.desc}
                    />
                  </div>
                )
              } else {
                return (
                  <ActionCard
                    title={item.title}
                    smallText={item.desc}
                    buttonText="了解更多"
                  />
                )
              }
            })}
            <div className={css.item}>
              <FeatureCard
                icon={icon1}
                title="分期貸款"
                description="最常見的私人貸款模式，借款人獲批貸款後，每月定期及定額地還款"
              />
            </div>
            <div className={css.item}>
              <FeatureCard
                icon={icon2}
                title="循環貸款"
                description="申請人獲批貸款限額後，還款期及還款額視乎借款人實際提取的金額而定，原理與信用卡簽賬類似"
              />
            </div>
            <div className={css.item}>
              <ActionCard
                title="貸款符合各種個人理財需要/n網上私人貸款即時批核！"
                smallText="申請貸款只需身份證及電話，借錢更方便。"
                buttonText="了解更多"
              />
            </div>
            <div className={css.item}>
              <FeatureCard
                icon={icon3}
                title="結餘轉戶貸款"
                description="用途是以較佳利率還清其他貸款，而非用作提供現金，但近年市面亦有可用作套現的結餘轉戶貸款"
              />
            </div>
            <div className={css.item}>
              <FeatureCard
                icon={icon4}
                title="稅務貸款"
                description="為私人分期貸款的其中一種，常於每年10月至翌年4月推出，利率通常比一般私人貸款優惠。貸款用途名義上是交稅，但實際上沒有限制"
              />
            </div>
            <div className={css.item}>
              <FeatureCard
                icon={icon5}
                title="汽車貸款"
                description="用汽車作為抵押品，以取得貸款購買汽車，然後分期償還貸款"
              />
            </div>
          </Slider>
        </div>
      )}
    </>
  )
}
