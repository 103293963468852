import React, { useEffect, useRef, useState } from "react"
import Icon1 from "./../images/featureSection/icon-1.png"
import Icon2 from "./../images/featureSection/icon-2.png"
import Icon3 from "./../images/featureSection/icon-3.png"
import PersonImage from "./../images/featureSection/people-1.png"

import "./css/featureSection.css"

import IconWithText from "../compoients/IconWithText"
import { useMediaQuery } from "react-responsive"
import { useNavigate } from "react-router-dom"

const FeatureSection = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 961px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" })
  const isMobile_toogle = useMediaQuery({ query: "(max-width: 996px)" })
  const featureList = [
    {
      refNum: "1",
      icon: Icon1,
      title: "免TU免露面 無須審閱過往任何財務或信貸記錄",
      desc: "FFL貸款產品免TU審查，無須提交入息證明文件，全程足不出戶，現金最快15分鐘到手，財務困擾即日KO！",
    },
    {
      refNum: "2",
      icon: Icon2,
      title: "彈性還款期數由您決定",
      desc: "貸款人可根據自身的還款能力自訂還款期，減輕你的財務壓力。即使提早清還全數貸款金額亦無須擔心被罰息！",
    },
    {
      refNum: "3",
      icon: Icon3,
      title: "極簡化申請流程 絕無隱藏收費",
      desc: "FFL網上貸款申請流程只需簡單填寫表格，2分鐘即可完成申請 A.I智能批核會於10分鐘內完成批核，最快15分鐘現金收到，絕無任何額外收費，貸款額全數到手！",
    },
  ]
  const navigate = useNavigate()

  const getRef = (refNum) => {
    switch (refNum) {
      case "1":
        return icon_text_ref1
      case "2":
        return icon_text_ref2
      case "3":
        return icon_text_ref3
      default:
        return null
    }
  }
  const [pageYOffset, setPageYOffset] = useState(0)
  useEffect(() => {
    function handleScroll() {
      setPageYOffset(window.scrollY)
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const head_ref = useRef(null)
  const icon_text_ref1 = useRef(null)
  const icon_text_ref2 = useRef(null)
  const icon_text_ref3 = useRef(null)
  const btn_ref = useRef(null)

  useEffect(() => {
    // if (isDesktop) {
    //   if (pageYOffset >= 700) {
    //     home_section2_ref.current.classList.add("fade-up-element")
    //   }
    // }
    if (isDesktop || isMobile || isMobile_toogle) {
      if (pageYOffset >= 200) {
        head_ref.current.classList.add("fade-up-element-slow")
      }
      if (pageYOffset >= 350) {
        icon_text_ref1.current.classList.add("fade-up-element-slow")
      }
      if (pageYOffset >= 470) {
        icon_text_ref2.current.classList.add("fade-up-element-slow")
      }
      if (pageYOffset >= 600) {
        icon_text_ref3.current.classList.add("fade-up-element-slow")
      }
      if (pageYOffset >= 750) {
        btn_ref.current.classList.add("fade-up-element-slow")
      }
    }
  }, [pageYOffset])

  return (
    <div className="feature-section">
      <div className="feature-text color2">
        <div className="mb-4 opactiy0" ref={head_ref}>
          <h1>FFL網上貸款免TU</h1>
          <h1>嶄新網貸平台 即時獲悉批核結果</h1>
        </div>

        <div className="feature-items">
          {featureList.map((item, idx) => (
            <div key={idx} ref={getRef(item.refNum)} className="opactiy0">
              <IconWithText
                icon={item.icon}
                title={item.title}
                desc={item.desc}
              />
            </div>
          ))}
        </div>
        <button
          className="feature-button button_hover_motion opactiy0"
          ref={btn_ref}
          onClick={() => {
            navigate("/apply")
          }}
        >
          了解FFL免TU貸款
        </button>
      </div>
      <div className="feature-image">
        <img src={PersonImage} alt="Person skiing" />
      </div>
    </div>
  )
}

export default FeatureSection
