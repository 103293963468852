import { Helmet } from "react-helmet"

export function MetaPixel({ data }) {
  const MetaPixelScript = data?.MetaPixelScript?.find((item) => item.label === "MetaPixelScript")?.description
  const MetaPixelNoScript = data?.MetaPixelNoScript?.find((item) => item.label === "MetaPixelNoScript")?.description

  return (
    <Helmet>
      <script>
        {MetaPixelScript}
      </script>
      <noscript>
        {MetaPixelNoScript}
      </noscript>
    </Helmet>
  )
}
