import "./css/loanProduct.css"
import banner from "../images/privateloan/banner.png"
import banner_tablet768 from "../images/privateloan/banner_tablet768.png"
import banner_mobile425 from "../images/privateloan/banner-1-mobile.png"
import stepsImage from "../images/privateloan/application_procedure.jpg"
import { useEffect } from "react"
import "./css/privateLoan.css"
import { ApplyBtnBig } from "../compoients/Btn"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import FeatureGrid from "../compoients/FeatureGrid"
import ApplySteps from "../section/home/ApplySteps"

const PrivateLoan = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 960px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 961px)" })
  const isMobile_small = useMediaQuery({ query: "(max-width: 600px)" })
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])
  const navigate = useNavigate()
  const backgroundImage = isMobile_small
    ? banner_mobile425
    : isTablet
    ? banner
    : banner

  return (
    <div className="loanProduct-container mortgage marginFixTop">
      <div
        className="loanProduct-banner"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="loanProduct-banner-text">
          <h1>私人貸款</h1>
          {/* <hr className="loanProduct-banner-divider" /> */}
          <p>
            FFL私人貸款，資金靈活周轉，卡數一筆清，財務問題瞬間解決。不設任何手續費，特快低息私人貸款，預先批核只需10分鐘，免信貸（TU）評級。特設彈性還款期，立即與聯絡我們遞交申請，私人貸款靈活易批，財務周轉問題即日解決！
          </p>
          <button
            className="applyNowBtn_banner button_hover_motion2 "
            onClick={() => {
              navigate("/apply")
            }}
          >
            立即申請
          </button>
        </div>
      </div>
      <div className="loanProduct-content">
        <div className="privateLoan-container">
          <FeatureGrid />
          <div style={{ marginTop: "100px", marginBottom: "80px" }}>
            <ApplySteps />
          </div>

          {/* <div className="mt-5">
            <ApplyBtnBig color={"#632e8d"} handle={() => navigate("/apply")} />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default PrivateLoan
