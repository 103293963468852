import "./css/loanProduct.css"

import banner from "../images/mortgage/banner.jpg"
import banner_tablet768 from "../images/privateloan/banner_tablet768.png"
import banner_mobile425 from "../images/privateloan/banner_mobile425.png"
import stepsImage from "../images/privateloan/application_procedure.jpg"
import { useEffect } from "react"

import "./css/mortgage.css"
import { ApplyBtnBig } from "../compoients/Btn"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import ApplySteps from "../section/home/ApplySteps"
import FeatureGrid_Mortgage from "../compoients/FeatureGrid_Mortgage"

const Mortgage = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 960px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 961px)" })
  const isMobile_small = useMediaQuery({ query: "(max-width: 600px)" })
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])
  const navigate = useNavigate()
  const backgroundImage = isMobile_small ? banner : isTablet ? banner : banner

  return (
    <div className="loanProduct-container mortgage marginFixTop">
      <div
        className="loanProduct-banner"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="loanProduct-banner-text">
          <h1>大額業主貸款</h1>
          <p>
            不論樓齡，物業種類或半邊業權，借貸金額可高達樓價八成。只要您是業主，便合資格申請我們專為業主而設的私人貸款計劃。15分鐘初步審批，24小時現金到手，我們保證您能在最短時間內解決現金周轉問題。
          </p>
          <button
            className="applyNowBtn_banner button_hover_motion2 "
            onClick={() => {
              navigate("/apply")
            }}
          >
            立即申請
          </button>
        </div>
      </div>
      <div className="loanProduct-content">
        <div className="mortgage-container">
          <h2>業主應急錢</h2>
          <h2 className="mb-5">業主私人貸款計劃特點</h2>
          <FeatureGrid_Mortgage />
          <div style={{ marginTop: "100px", marginBottom: "80px" }}>
            <ApplySteps />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mortgage
