import css from "./css/applicationForm.module.css"
import "./css/applicationForm.css"
import { Form, Button, Alert } from "react-bootstrap"
import CurrencyInput from "react-currency-input-field"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import { useState, useEffect } from "react"
import { NextStepBtn, SubmitBtnBig, PrevStepBtn } from "./Btn"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import img1 from "../images/applyForm/target1.png"
import img2 from "../images/applyForm/target2.png"
import img3 from "../images/applyForm/target3.png"
import img4 from "../images/applyForm/target4.png"

import moment from "moment"

import { ENV } from "../config"
import { useNavigate } from "react-router-dom"
import { auth } from "../firebase"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import { createUser } from "../signature/firebaseConf"
import { ToggleBtnGroup } from "./ToggleBtnGroup"
import { FormControl } from "@mui/material"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { sanitizeInput } from "../ults/PrevientXSS"

const ApplicationForm = () => {
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [step, setStep] = useState(1)
  const [title, setTitle] = useState("")
  const [title2, setTitle2] = useState("")

  /**step1 */
  const [fullName, setFullName] = useState("")
  const [phoneNumb, setPhoneNumb] = useState("")
  const [agreement, setAgreement] = useState(false)

  /**end step 1 */

  /**step2 */
  const [loanTarget, setLoanTarget] = useState("私人貸款")
  const [amount, setAmount] = useState("")
  const [paymentTerms, setPaymentTerms] = useState("12")

  const [loan_selectID, setLoan_selectID] = useState("")

  const [lastName, setLastName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [idCard, setIdCard] = useState("")
  const [startDate, setStartDate] = useState(new Date())
  const [birthDate, setBirthDate] = useState("")
  const [sex, setSex] = useState("")
  const [sex_selectID, setSex_selectID] = useState("")

  const [paymentTermsID, setPaymentTerms_ID] = useState("")
  const [employment, setEmployment] = useState()
  const [employmentID, setEmploymentID] = useState("")
  const [sameToPropertyInfo, setSameToPropertyInfo] = useState("")

  /**end step 2 */
  const loanTargetList = ["私人貸款", "結餘轉戶", "業主私人貸款", "中小企貸款"]
  const paymentTermsList = ["6", "12", "18", "24", "30", "36", "42", "48", "60"]

  /**step 3 */
  const [occupation, setOccupation] = useState("全職")
  const [occ_selectID, setOcc_selectID] = useState("ftEmployee")
  const [payMethod, setPayMethod] = useState("自動轉賬")
  const [pay_selectID, setPay_selectID] = useState("pay1")
  const [income, setIncome] = useState("")
  const [agreeNext, setAgreeNext] = useState("")
  const [industry, setIndustry] = useState("bank")

  const [liveBlock, setLiveBlock] = useState("")
  const [liveUnit, setLiveUnit] = useState("")
  const [liveFloor, setLiveFloor] = useState("")
  const [liveAddress, setLiveAddress] = useState("")
  const [liveRegion, setLiveRegion] = useState("")
  const [liveMethod, setLiveMethod] = useState()
  const [liveMethodID, setLiveMethodID] = useState("")

  const [propertyBlock, setPropertyBlock] = useState("")
  const [propertyUnit, setPropertyUnit] = useState("")
  const [propertyFloor, setPropertyFloor] = useState("")
  const [propertyAddress, setPropertyAddress] = useState("")
  const [propertyRegion, setPropertyRegion] = useState("")
  const [propertyMethod, setPropertyMethod] = useState()
  const [propertyMethodID, setPropertyMethodID] = useState("")
  const [propertyMortageState, setPropertyMortageState] = useState("")

  const [carTypeMode, setCarTypeMode] = useState("")
  const [carTypeModeID, setCarTypeModeID] = useState("")
  const [carBrand, setCarBrand] = useState("")
  const [carColor, setCarColor] = useState("")
  const [carFirstMile, setCarFirstMile] = useState("")
  const [carMile, setCarMile] = useState("")
  const [carLuxInfo, setCarLuxInfo] = useState("")
  const [carBrandFactory, setCarBrandFactory] = useState("")
  const [carModel, setCarModel] = useState("")
  const [carYear, setCarYear] = useState("")
  const [nationality, setNationality] = useState("")

  const [companyName, setCompanyName] = useState("")
  const [companyPhone, setCompanyPhone] = useState()
  const [companyAddress, setCompanyAddress] = useState("")
  const [position, setPosition] = useState("")

  const [carType, setCarType] = useState()
  const [carTypeID, setCarTypeID] = useState("")
  /**step3 */

  /**firebase phone verify */
  // const [phoneVerified, setPhoneVerified] = useState(false)

  // const countryCode = "+852"
  // const [expandForm, setExpandForm] = useState(false)
  // const [showsendBtn, setShowsendBtn] = useState(true)
  // const [error, setError] = useState("")
  // // eslint-disable-next-line no-unused-vars
  // const [OTP, setOTP] = useState("")
  // const [isSentCode, setIsSentCode] = useState(false)
  // const [redo, setRedo] = useState(false)
  // const [isSuccess, setIsSuccess] = useState(false)
  // const [showRecaptcha, setShowRecaptcha] = useState(true)
  // const restart = () => {
  //   setError("")
  //   setExpandForm(false)
  //   setOTP("")
  //   setIsSentCode(false)
  //   setRedo(false)
  //   setIsSuccess(false)
  //   setShowsendBtn(true)
  //   setShowRecaptcha(true)
  // }

  // const sentCode = () => {
  //   setError("")
  //   setExpandForm(true)
  //   setIsSentCode(true)
  // }
  // const failsendCode = () => {
  //   setIsSentCode(false)
  //   setRedo(true)
  //   setIsSuccess(false)
  //   setShowsendBtn(false)
  // }

  // const verified = () => {
  //   setError("")
  //   setPhoneVerified(true)
  //   setIsSuccess(true)
  // }
  // const notVerified = () => {
  //   setPhoneVerified(false)
  //   setRedo(true)
  // }

  // const generateRecaptha = () => {
  //   setShowRecaptcha(true)
  //   window.recaptchaVerifier = new RecaptchaVerifier(
  //     "recaptcha-container",
  //     {
  //       size: "invisible",
  //       callback: (response) => {
  //         // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       },
  //     },
  //     auth
  //   )
  // }

  // const requestOTP = (e) => {
  //   e.preventDefault()
  //   if (phoneNumb.length >= 8) {
  //     generateRecaptha()
  //     let appVerifier = window.recaptchaVerifier
  //     signInWithPhoneNumber(auth, countryCode + phoneNumb, appVerifier)
  //       .then((confirmationResult) => {
  //         window.confirmationResult = confirmationResult
  //         sentCode()
  //       })
  //       .catch((error) => {
  //         //error sms not sent
  //         setShowRecaptcha(false)
  //         console.log(error.message)
  //         if (error.message.includes("too-many-requests")) {
  //           setError("抱歉!嘗試次數太多，請稍後再試。")
  //           failsendCode()
  //         } else if (error.message.includes("INVALID_CODE")) {
  //           setError("驗證碼錯誤，請重新輸入")
  //           failsendCode()
  //         } else if (error.message.includes("TOO_LONG")) {
  //           setError("無效電話號碼，請重新輸入")
  //           failsendCode()
  //         }
  //         failsendCode()
  //       })
  //   } else {
  //     return setError("請輸入正確號碼")
  //   }
  // }

  // const verifyOTP = (e) => {
  //   let otp = e.target.value
  //   setOTP(otp)
  //   if (otp.length === 6) {
  //     //verify otp
  //     let confirmationResult = window.confirmationResult
  //     confirmationResult
  //       .confirm(otp)
  //       .then((result) => {
  //         // User signed in successfully.
  //         //   const user = result.user
  //         verified()
  //       })
  //       .catch((error) => {
  //         if (error.message.includes("invalid")) {
  //           setError("驗證碼錯誤，請重新輸入")
  //         } else if (error.message.includes("expired")) {
  //           setError("驗證碼過期")
  //         }
  //         notVerified()
  //       })
  //   }
  // }
  /**end firebase phone verify */

  /**validator*/

  // useEffect(() => {
  //   if (checkRepeatNumber() === false && checkPhoneFormat() === true) {
  //     setPhoneVerified(true)
  //   } else {
  //     setPhoneVerified(false)
  //   }
  // }, [phoneVerified, phoneNumb])

  useEffect(() => {
    const result = checkNormalSequenceNumber("12345")
    console.log("check sequence", result)
  }, [])

  function checkRepeatNumber() {
    let flag = false
    if (
      phoneNumb.includes("11111") ||
      phoneNumb.includes("22222") ||
      phoneNumb.includes("33333") ||
      phoneNumb.includes("44444") ||
      phoneNumb.includes("55555") ||
      phoneNumb.includes("66666") ||
      phoneNumb.includes("77777") ||
      phoneNumb.includes("88888") ||
      phoneNumb.includes("99999") ||
      phoneNumb.includes("00000")
    ) {
      flag = true
      return flag
    } else {
      flag = false
      return flag
    }
  }

  function checkNormalSequenceNumber(numb) {
    let flag = true
    let countSequence = 0

    for (let i = 1; i < numb.length; i++) {
      let diff = numb.substring(i) - numb.substring(i + 1)
      if (diff === 1) {
        countSequence++
      }
      if (countSequence >= 5) {
        flag = false
      }
    }
  }

  function checkPhoneFormat() {
    let reg = /^[2-9][0-9]{7}$/gm
    if (reg.test(phoneNumb) && phoneNumb.length === 8) {
      return true
    } else {
      return false
    }
  }
  function checkIDCard(str) {
    const strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    if (str.length < 8) {
      return false
    }
    str = str.toUpperCase()
    const hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/
    const matchArray = str.match(hkidPat)
    if (matchArray == null) {
      return false
    }
    const charPart = matchArray[1]
    const numPart = matchArray[2]
    const checkDigit = matchArray[3]
    let checkSum = 0
    if (charPart.length == 2) {
      checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)))
      checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)))
    } else {
      checkSum += 9 * 36
      checkSum += 8 * (10 + strValidChars.indexOf(charPart))
    }

    for (let i = 0, j = 7; i < numPart.length; i++, j--) {
      checkSum += j * numPart.charAt(i)
    }
    const remaining = checkSum % 11
    const verify = remaining == 0 ? 0 : 11 - remaining
    return verify == checkDigit || (verify == 10 && checkDigit == "A")
  }

  /**end validator */

  function handleNextStep() {
    if (step === 1) {
      console.log(agreement)
      if (!fullName || !idCard || !phoneNumb || agreement === false) {
        alert("請填寫正確資料")
        return
      }
      //check Hong Kong ID card by using checkIDCard function
      if (!checkIDCard(idCard)) {
        alert("請填寫正確香港身份證號碼")
        return
      }

      if (checkRepeatNumber() === true || checkPhoneFormat() === false) {
        alert("請填寫正確電話號碼")
        return
      }
      if (idCard.length !== 8) {
        alert("請填寫正確香港身份證號碼")
        return
      }
    }
    setStep(step + 1)
    window.scrollTo({ top: 0, behavior: "instant" })
  }

  function handlePrevStep() {
    setStep(step - 1)
    window.scrollTo({ top: 0, behavior: "instant" })
  }

  function handleDate(e) {
    setStartDate(e)
    const newDate = new Date(e)
    let day = "/" + (newDate.getUTCDate() + 1)
    let month = "/" + (newDate.getUTCMonth() + 1)
    let year = newDate.getUTCFullYear()
    setBirthDate(year + month + day)
  }

  //send Data
  const handleSubmit = async () => {
    if (isSubmitting) return // 防止重複提交
    setIsSubmitting(true) // 鎖定提交
    if (step === 1) {
      if (!fullName || !idCard || phoneNumb || agreement === false) {
        alert("請填寫正確資料")
        return
      }
    }
    if (step === 2) {
      console.log(loanTarget)
      console.log(amount)
      console.log(paymentTerms)
      if (!loanTarget || !amount || !paymentTerms) {
        alert("請填寫正確資料")
        return
      }
    }

    // 对输入的字段进行清理

    let sanitizedFullName = sanitizeInput(fullName)
    let sanitizedPhoneNumb = sanitizeInput(phoneNumb)
    let sanitizedIdCard = sanitizeInput(idCard)
    let sanitizedLoanTarget = sanitizeInput(loanTarget)
    let sanitizedAmount = sanitizeInput(amount)
    let sanitizedPaymentTerms = sanitizeInput(paymentTerms)

    let data = {
      fullName: sanitizedFullName,
      phoneNumb: sanitizedPhoneNumb,
      idCard: sanitizedIdCard,
      loanTarget: sanitizedLoanTarget,
      amount: sanitizedAmount,
      paymentTerms: sanitizedPaymentTerms,
      agreement: agreement,
      timestamp: moment().utcOffset(8).format("YYYY-MM-DD HH:mm:ss"),
    }

    // const userData = {
    //   idCard: idCard,
    //   password: phoneNumb,
    //   displayName: lastName + firstName,
    //   loanNo: idCard,
    // }
    // console.log(data)
    // return
    //create user after submit the enquiry form
    // await createUser(userData)

    fetch(ENV + "/api/v1/application", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          navigate("/thankyou")
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
      .finally(() => {
        setIsSubmitting(false) // 解鎖提交按鈕
      })
  }

  // const step1Pass = fullName && idCard && phoneNumb && paymentTerms && isSuccess
  const step1Pass = fullName && idCard && phoneNumb
  const step2Pass = amount && loanTarget && paymentTerms

  return (
    <div className={`${css.container} apply-form`}>
      <div className="mb-5">
        <h5>
          請先填寫以下所需資料，讓我們可以加快審批過程。我們或有可能會聯絡閣下提供更多申請所需的資料。
        </h5>
      </div>
      <div className={css.form_container}>
        <div className={css.form_title_container}>
          <h3>貸款信息</h3>
        </div>
        {step === 1 && (
          <div className={css.step1_container}>
            <div className={css.step1_row1}>
              <Form.Group className={css.formGroup}>
                <Form.Label>
                  姓名<span className={css.requiredStar}>*</span>
                </Form.Label>
                <Form.Control
                  type="Text"
                  id="fullName"
                  value={fullName}
                  placeholder="身份證全稱"
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className={css.formGroup}>
                <Form.Label>
                  香港身份證號碼<span className={css.requiredStar}>*</span>
                </Form.Label>
                <Form.Control
                  type="Text"
                  id="idCard"
                  value={idCard}
                  placeholder="例如：Z1234567"
                  onChange={(e) => setIdCard(e.target.value)}
                  // className={css.textbox}
                />
              </Form.Group>
            </div>
            <div className={css.step1_row2}>
              <Form.Group className={css.formGroup2}>
                <Form.Label>
                  聯絡電話<span className={css.requiredStar}>*</span>
                </Form.Label>
                <Form.Control
                  type="Text"
                  id="phoneNumb"
                  aria-describedby="phoneHelpBlock"
                  value={phoneNumb}
                  placeholder="例如：6XXXXXXX"
                  onChange={(e) => setPhoneNumb(e.target.value)}
                />

                {/* SMS 驗證碼 */}
                {/* <Form.Text id="phoneHelpBlock" className="text-muted">
                  請提供有效流動電話號碼，以便接收密碼短訊
                </Form.Text>
                {!expandForm && showsendBtn && (
                  <Button
                    className="theme_btn mt-3 mb-2"
                    onClick={requestOTP}
                    disabled={!phoneVerified}
                    style={{ alignSelf: "start", fontSize: "13px" }}
                  >
                    送出驗證碼
                  </Button>
                )}

                {!phoneVerified && phoneNumb.length > 8 && (
                  <Form.Label className="alert-label alertcolor">
                    請輸入正確號碼
                  </Form.Label>
                )}

                {expandForm && (
                  <div className="d-flex">
                    <Form.Group>                      
                      <Form.Control
                        type="otp"
                        placeholder="輸入驗證碼"
                        onChange={verifyOTP}
                        disabled={isSuccess}
                        className="mt-1 mb-1"
                      />                      
                    </Form.Group>
             
                  </div>
                )}

                {showRecaptcha && !redo && (
                  <div style={{ zIndex: 999 }} id="recaptcha-container"></div>
                )}

                <div className="d-flex flex-column">
                  {redo && (
                    <Button className="mb-3 theme_btn mt-2" onClick={restart}>
                      重新驗證
                    </Button>
                  )}

                  {isSentCode && !redo && (
                    <p className="msg-success">驗證碼已寄出</p>
                  )}
                </div>
                {error && (
                  <Alert
                    className="mt-2"
                    variant="danger"
                    style={{ width: "20%" }}
                  >
                    {error}
                  </Alert>
                )}
                {isSuccess && <p className="msg-success">✓ 電話號碼驗證成功</p>} */}
              </Form.Group>
            </div>

            <FormControlLabel
              required
              className="mt-1"
              style={{ paddingLeft: "10px" }}
              control={
                <Checkbox
                  checked={agreement}
                  onChange={(e) => {
                    setAgreement(e.target.checked)
                  }}
                />
              }
              label="*本人已年滿18歲，閱讀並同意此網上的貸款細節及條款。 本人明白和同意，在此申請表提供的個人資料將
              記錄於資料系統作申請用途，在未獲得您同意前，不會向第三方披露你的個人資料。"
            />

            <button
              disabled={!step1Pass}
              onClick={step1Pass ? handleNextStep : null}
              className="application-btn mt-5"
              style={{ placeSelf: "center" }}
            >
              下一步
            </button>
          </div>
        )}

        {step === 2 && (
          <div className={css.step1_container}>
            <div className={css.step1_row1}>
              <Form.Group className={css.formGroup}>
                <Form.Label>貸款種類</Form.Label>
                <select
                  className={css.formSelect}
                  name="loanTarget"
                  value={loanTarget}
                  onChange={(e) => setLoanTarget(e.target.value)}
                >
                  {loanTargetList.map((item, idx) => (
                    <option key={idx} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </div>
            <div className={css.step2_row2}>
              <Form.Group className={css.formGroup}>
                <Form.Label>貸款金額</Form.Label>
                <CurrencyInput
                  id="currency"
                  name="currency"
                  defaultValue={amount}
                  decimalsLimit={2}
                  prefix={"HK$ "}
                  placeholder="HK$"
                  onValueChange={(value, name) => setAmount(value)}
                  className="currency-input"
                />
                <br />
                <Form.Text className="text-muted">
                  最高可申請額度為HK$ xxx,xxx
                </Form.Text>
              </Form.Group>
              <Form.Group className={css.formGroup}>
                <Form.Label>還款期數</Form.Label>
                <select
                  className={css.formSelect}
                  name="paymentTerms"
                  value={paymentTerms}
                  onChange={(e) => setPaymentTerms(e.target.value)}
                >
                  {paymentTermsList.map((item, idx) => (
                    <option key={idx} value={item}>
                      {item} 個月
                    </option>
                  ))}
                </select>
              </Form.Group>
            </div>

            <div className="d-flex align-self-center mobFlex">
              <button
                onClick={handlePrevStep}
                className="application-btn form_previous_btn "
              >
                上一步
              </button>
              <button
                disabled={!step2Pass || isSubmitting} // 當提交中時按鈕會被禁用
                onClick={step2Pass && !isSubmitting ? handleSubmit : null} // 禁用提交過程中的再次點擊
                className="application-btn form_apply_btn "
              >
                {isSubmitting ? "提交中..." : "立即申請"}{" "}
                {/* 提交過程中顯示不同的按鈕文字 */}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ApplicationForm
