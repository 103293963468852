export const data1 = [
  {
    order: 1,
    question: "Q1. FFL提供甚麼類型的貸款服務？",
    answer:
      "FFL是一家持有合法放債人牌照的公司，提供多種不同貸款服務如低息私人貸款、免TU免入息證明文件貸款、清卡數（結餘轉戶）、大額業主貸款及中小企周轉貸款等等，全程可經網上申請、批核，最快15分鐘現金立即到手。",
  },
  {
    order: 2,
    question: "Q2. 怎樣申請FFL貸款服務？",
    answer:
      "全程可由網上申請，只需填寫簡單登記表格、2分鐘即可完成，全程AI批核，10分鐘獲悉批核結果。如有任何查詢，可致電2538-8300或Whatsapp 92840210，我們會有專人提供協助。",
  },
  {
    order: 3,
    question: "Q3. 貸款申請需要提供甚麼文件？",
    answer:
      "只需提交簡單個人資料如身份證以作核對便可，閣下所有提交資料絕對全程保密。",
  },
  {
    order: 4,
    question: "Q4.貸款額度和還款期數大概為多少？",
    answer:
      "FFL致力為客戶即日解決一切財務困擾，貸款額度可高達100萬元或月薪十倍，還款期數可長達72期。",
  },
  {
    order: 5,
    question: "Q5.貸款所收取的利率普遍大約為多少？",
    answer:
      "貸款的利息的確會因人而異，過往最低批出的利率為8%。同時，FFL提供AI運算服務，能為不同客戶打造至佳財務貸款方案，轉會到FFL的客戶更可享利率8折優惠。",
  },
  {
    order: 6,
    question: "Q6. 甚麼為免TU私人貸款？",
    answer:
      "免TU私人貸款意思即無須查閱閣下信貸報告，全程絕不影響閣下的TU評級。",
  },
  {
    order: 7,
    question: "Q7. FFL私人貸款一定需要露面或上分行嗎？",
    answer: "不需要，FFL提供網貸服務，只需填寫簡單表格，全程可經網上批核。",
  },
  {
    order: 8,
    question: "Q8. FFL提供免入息貸款嗎？",
    answer:
      "我們同樣照顧收入不穩定或從事自由工作客戶的財務問題，即使未能提供入息文件亦可獲批十萬以下的貸款額。",
  },
  {
    order: 9,
    question: "Q9. 除外利息外，還有其他額外／附加收費嗎？",
    answer:
      "沒有。FFL不同於其他財務公司，申請貸款途中會列明所有利息、還款期數、每期還款額，絕無其他隱藏收費。",
  },
  {
    order: 10,
    question: "Q10. 會有專人可以協助貸款申請嗎？",
    answer:
      "有的。FFL有多位財務貸款專員協助閣下申請，詳情可致電2538-8300或Whatsapp 92840210。我們會盡快提供協助。",
  },
]

export const data2 = [
  {
    order: 1,
    question: "Q : FFL信貸的利率是多少？",
    answer: "A : FFL信貸的利率根據您的信用評分和申請金額而定。",
  },
  {
    order: 2,
    question: "Q : 我可以提前還款嗎？",
    answer: "A : 是的，您可以隨時提前還款，並且不會收取額外的提前還款費用。",
  },
  {
    order: 3,
    question: "Q : 能否更改我的還款計劃？",
    answer: "A : 如果您希望更改還款計劃，請聯繫我們的客服熱線以進行修改。",
  },
]
export const data3 = [
  {
    order: 1,
    question: "Q : FFL信貸的利率是多少？",
    answer: "A : FFL信貸的利率根據您的信用評分和申請金額而定。",
  },
  {
    order: 2,
    question: "Q : 我可以提前還款嗎？",
    answer: "A : 是的，您可以隨時提前還款，並且不會收取額外的提前還款費用。",
  },
  {
    order: 3,
    question: "Q : 能否更改我的還款計劃？",
    answer: "A : 如果您希望更改還款計劃，請聯繫我們的客服熱線以進行修改。",
  },
]
export const data4 = [
  {
    order: 1,
    question: "Q : FFL信貸的利率是多少？",
    answer: "A : FFL信貸的利率根據您的信用評分和申請金額而定。",
  },
  {
    order: 2,
    question: "Q : 我可以提前還款嗎？",
    answer: "A : 是的，您可以隨時提前還款，並且不會收取額外的提前還款費用。",
  },
  {
    order: 3,
    question: "Q : 能否更改我的還款計劃？",
    answer: "A : 如果您希望更改還款計劃，請聯繫我們的客服熱線以進行修改。",
  },
]
export const data5 = [
  {
    order: 1,
    question: "Q : FFL信貸的利率是多少？",
    answer: "A : FFL信貸的利率根據您的信用評分和申請金額而定。",
  },
  {
    order: 2,
    question: "Q : 我可以提前還款嗎？",
    answer: "A : 是的，您可以隨時提前還款，並且不會收取額外的提前還款費用。",
  },
  {
    order: 3,
    question: "Q : 能否更改我的還款計劃？",
    answer: "A : 如果您希望更改還款計劃，請聯繫我們的客服熱線以進行修改。",
  },
]
