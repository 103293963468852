import { Fragment } from "react"
import "./css/featureGrid.css"
import { Grid, Paper, Typography, Button, Box } from "@mui/material"
import ImageIcon from "@mui/icons-material/Image" // Placeholder for the icon, replace with your icons
import icon1 from "../images/mortgage/icon-1.png"
import icon2 from "../images/mortgage/icon-2.png"
import icon3 from "../images/mortgage/icon-3.png"
import icon4 from "../images/mortgage/icon-4.png"
import icon5 from "../images/mortgage/icon-5.png"
import icon6 from "../images/mortgage/icon-6.png"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import css from "./css/cardSlider.module.css"
import { useMediaQuery } from "react-responsive"

const List1 = [
  { icon: icon1, title: "毋須抵押物業樓契" },
  { icon: icon2, title: "免入息證明" },
  { icon: icon3, title: "不會影響信貸評級" },
  { icon: icon4, title: "全程網上辦理" },
  { icon: icon5, title: "最快10分鐘完成批核" },
]

const List2 = [
  {
    icon: icon6,
    title: "物業一按",
    desc: "客戶只需遞交物業樓契，證明業主身份便可申請一按貸款。不限物業樓齡及種類，毋需入息證明，貸款額為最高可達物業估價8成。",
  },
  {
    icon: icon6,
    title: "物業二按",
    desc: "物業二按，是以尚未供完的物業加按套現，用以生意資金周轉。業主毋需抵押樓契，原本銀行的按揭繼續保留，貸款額更可高達物業估值的8成。",
  },
]

const FeatureCard = ({ icon, title }) => {
  const titleParts = title.split("/n").map((part, index) => (
    <Fragment key={index}>
      {part}
      {index < title.split("/n").length - 1 && <br />}
    </Fragment>
  ))

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      elevation={2}
      className="feature-card-paper"
    >
      <Box sx={{ mb: 2, height: "100px" }}>
        <img src={icon} alt="icon" className="feature-card-img" />
      </Box>
      <Typography
        variant="span"
        component="span"
        sx={{ mb: 1, fontWeight: "500", fontSize: "1rem" }}
      >
        {titleParts}
      </Typography>
      {/* <Typography variant="body1">{description}</Typography> */}
    </Paper>
  )
}

const FeatureCard2 = ({ icon, title, description }) => {
  const titleParts = title.split("/n").map((part, index) => (
    <Fragment key={index}>
      {part}
      {index < title.split("/n").length - 1 && <br />}
    </Fragment>
  ))

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      elevation={2}
      className="feature-card-paper"
    >
      <Box sx={{ mb: 2 }}>
        <img src={icon} alt="icon" className="feature-card-img" />
      </Box>
      <Typography variant="h5" component="h5" sx={{ mb: 1, fontWeight: "500" }}>
        {titleParts}
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </Paper>
  )
}

const ActionCard = ({ title, smallText, buttonText }) => {
  const titleParts = title.split("/n").map((part, index) => (
    <Fragment key={index}>
      {part}
      {index < title.split("/n").length - 1 && <br />}
    </Fragment>
  ))
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        border: "none",
        boxShadow: "none",
      }}
      elevation={2}
    >
      <Typography variant="h6" component="h3" sx={{ mb: 3 }}>
        {titleParts}
      </Typography>
      <Typography variant="body2" sx={{ mb: 3 }}>
        {smallText}
      </Typography>
      <button className="feature-action-card-btn button_hover_motion2">
        {buttonText}
      </button>
    </Paper>
  )
}

export default function FeatureGrid_Mortgage() {
  const isBreakpoint = useMediaQuery({ minWidth: 300, maxWidth: 768 })
  const settings = {
    className: `${css.cardSlider_container}`,
    centerPadding: "20px",
    centerMode: true,
    swipeToSlide: true,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <div className="featureGrid-papers-container">
      {!isBreakpoint ? (
        <Grid container spacing={2} className="feature-grid">
          {List1.map((item, idx) => (
            <Grid
              item
              xs={2}
              sm={6}
              md={2.4}
              className="feature-grid-item"
              key={idx}
            >
              <FeatureCard icon={item.icon} title={item.title} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Slider {...settings}>
          {List1.map((item, idx) => (
            <div key={idx} className={css.item}>
              <Grid
                item
                xs={2}
                sm={6}
                md={2.4}
                className="feature-grid-item"
                key={idx}
              >
                <FeatureCard icon={item.icon} title={item.title} />
              </Grid>
            </div>
          ))}
        </Slider>
      )}

      <div className="feature-grid-items">
        {List2.map((item, idx) => (
          <FeatureCard2
            icon={item.icon}
            title={item.title}
            description={item.desc}
            key={idx}
          />
        ))}
      </div>
    </div>
  )
}
